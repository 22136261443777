@charset "UTF-8";
.top-pdf_link {
  display: flex;
  position: fixed;
  top: 173px;
  right: 0;
  padding: 23px 12px 29px 12px;
  writing-mode: vertical-rl;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #3AA3AE;
  border-radius: 15px 0 0 15px;
  border: 1px solid #FFF;
  border-right: 0;
  z-index: 1;
  transition: 0.2s;
}

.top-pdf_link:after {
  position: relative;
  left: -3px;
  top: 12px;
  content: '';
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2215%22%3E%3Cpath%20data-name%3D%22Icon%20material-insert-drive-file%22%20d%3D%22M1.5%200A1.5%201.5%200%2000.008%201.5L0%2013.5A1.5%201.5%200%20001.492%2015H10.5a1.5%201.5%200%20001.5-1.5v-9L7.5%200zm5.25%205.25V1.125l4.125%204.125z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
  display: block;
  width: 12px;
  height: 15px;
}

.top-pdf_link:hover {
  background-color: #2d8089;
}

@media screen and (max-width: 767px) {
  .top-pdf_link {
    display: none;
  }
}

.top-main_visual {
  position: relative;
  display: block;
  margin-top: 94px;
  padding-bottom: 60px;
  /*
  &-1 {
    .top-main_visual--bg {
      background-image: url('/images/top-main-visual-01.jpg');
      @include sp {
        background-image: url('/images/top-main-visual-01-sp.jpg');
      }
    }
  }
  &-2 {
    .top-main_visual--bg {
      background-image: url('/images/top-main-visual-02.jpg');
      @include sp {
        background-image: url('/images/top-main-visual-02-sp.jpg');
      }
    }
  }
  &-3 {
    .top-main_visual--bg {
      background-image: url('/images/top-main-visual-03.jpg');
      @include sp {
        background-image: url('/images/top-main-visual-03-sp.jpg');
      }
    }
  }
  &--4 {
    position: relative;
    display: block;
  }*/
}

@media screen and (max-width: 767px) {
  .top-main_visual {
    margin-top: 70px;
    border-top: 0;
    height: auto;
    padding-bottom: 0;
  }
}

.top-main_visual--bg {
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  position: absolute;
  height: 100%;
  /*
    &-triangle {
      background-color: #3AA3AE;
      background-image: url("/images/top-bg-triangle.png");
      background-repeat: repeat;
    }
    */
}

@media screen and (max-width: 767px) {
  .top-main_visual--bg {
    background-position: center top;
    background-size: cover;
    z-index: 1;
    position: relative;
    height: 0;
  }
}

.top-main_visual--bg-border {
  background-image: url("/images/bg-top-main_visual.png");
  background-repeat: repeat;
  z-index: -1;
}

.top-main_visual--inner {
  width: 980px;
  margin: 0 auto;
  position: static;
  /*&-sp_thin { 適職探索、就活実践コースが中止のためメインビジュアルの高さを調整
      @include sp {
        padding: 0 0 3px;
      }
    }*/
}

@media screen and (max-width: 767px) {
  .top-main_visual--inner {
    width: 100%;
  }
}

.top-main_visual--wrap {
  display: flex;
  justify-content: space-between;
  padding: 30px 0 0;
  position: relative;
}

@media screen and (max-width: 767px) {
  .top-main_visual--wrap {
    display: block;
    background-image: url("/images/bg-top-main_visual.png");
    background-repeat: repeat;
    padding: 30px 15px 40px;
  }
}

.top-main_visual--text_area {
  padding: 56px 0 0;
  width: 390px;
}

@media screen and (max-width: 767px) {
  .top-main_visual--text_area {
    width: 100%;
    padding: 0;
    text-align: center;
  }
}

.top-main_visual--headline {
  margin: 0 0 22px;
}

@media screen and (max-width: 767px) {
  .top-main_visual--headline {
    margin: 0 0 16px;
  }
}

.top-main_visual--support {
  margin: 0 0 20px -16px;
}

@media screen and (max-width: 767px) {
  .top-main_visual--box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 24px;
  }
}

@media screen and (max-width: 767px) {
  .top-main_visual--img_program:first-child {
    width: 48%;
  }
  .top-main_visual--img_program:last-child {
    width: 52%;
  }
}

.top-main_visual--corse_box {
  width: 684px;
  margin: 0 auto;
  position: absolute;
  bottom: 135px;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.top-main_visual--corse_box.single {
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .top-main_visual--corse_box {
    width: calc(100% - 30px);
    /*margin: 82px auto 0;*/
    position: relative;
    top: 15px;
  }
}

.top-main_visual--button_area {
  position: relative;
}

.top-main_visual--button_area:before {
  content: '';
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  right: -24px;
  top: calc(50% - 10px);
}

.top-main_visual--button_area:after {
  content: '';
  width: 5px;
  height: 5px;
  border-top: 1px solid #3AA3AE;
  border-right: 1px solid #3AA3AE;
  transform: rotate(45deg);
  position: absolute;
  right: -18px;
  top: calc(50% - 5px);
}

.top-main_visual--link {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 16px 40px 16px 16px;
  margin: 0 0 0 -40px;
}

.top-main_visual--link:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transform: skewX(-12deg);
  background: #3AA3AE;
  border: 1px solid #fff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.16);
  transition: .15s;
}

.top-main_visual--link:hover:before {
  background: #2d8089;
}

@media screen and (max-width: 767px) {
  .top-main_visual--link {
    margin: 0;
  }
}

.top-main_visual--1day {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 12px;
}

@media screen and (max-width: 767px) {
  .top-main_visual--1day {
    position: relative;
    left: auto;
    bottom: auto;
    margin-top: 25px;
  }
}

.top-main_visual--1day_link {
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .top-main_visual--1day_link {
    display: block;
  }
}

.top-main_visual--1day_link:hover .top-main_visual--1day_pic {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transform: translateY(2px);
}

.top-main_visual--1day_pic {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transition: .15s;
}

@media screen and (max-width: 767px) {
  .top-main_visual--1day_pic {
    margin: 0 auto;
  }
}

.top-main_visual--online_anchor {
  position: absolute;
  left: 48px;
  bottom: -30px;
  width: 477px;
  height: 56px;
  border: 1px solid #fff;
  border-radius: 28px;
  background: #02679B;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 20px;
  font-weight: 900;
  color: #fff;
  padding: 3px 44px 3px 3px;
  transition: .15s;
}

@media screen and (max-width: 767px) {
  .top-main_visual--online_anchor {
    width: 100%;
    position: relative;
    left: auto;
    bottom: auto;
    font-size: 14px;
    height: 43px;
    margin: 24px auto 0;
    padding-right: 35px;
  }
}

.top-main_visual--online_anchor:hover {
  transform: translateY(5px);
}

.top-main_visual--online_anchor:after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_523%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20523%22%20transform%3D%22translate(3159.181%20-537.804)%20rotate(90)%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_81%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2081%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_86%22%20data-name%3D%22%E3%83%91%E3%82%B9%2086%22%20d%3D%22M547.8%2C3139.181a10%2C10%2C0%2C1%2C0%2C10%2C10A10%2C10%2C0%2C0%2C0%2C547.8%2C3139.181Zm-1.666%2C15.856-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(0%200)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: contain;
  position: absolute;
  top: calc(50% - 10px);
  right: 16px;
}

@media screen and (max-width: 767px) {
  .top-main_visual--online_anchor:after {
    width: 14px;
    height: 14px;
    top: calc(50% - 7px);
    right: 13px;
  }
}

.top-main_visual--online_anchor_heading {
  font-size: 14px;
  font-weight: 600;
  color: #02679B;
  line-height: 1.1;
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  margin-right: 8px;
}

@media screen and (max-width: 767px) {
  .top-main_visual--online_anchor_heading {
    font-size: 11px;
    width: 37px;
    height: 37px;
  }
}

.top-main_visual--online_anchor_text {
  text-align: left;
  margin-left: 3em;
}

.top-main_visual--online_anchor_colored {
  color: #FFE622;
}

.top-special_bnr {
  display: block;
  background-color: #b7b7b7;
  padding: 15px 0;
}

.top-special_bnr--link {
  display: block;
}

.top-special_bnr--link_img {
  max-width: 100%;
  display: block;
  height: auto;
  margin: 0 auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  transition: .15s;
}

@media screen and (max-width: 767px) {
  .top-special_bnr--link_img.pc {
    display: none;
  }
}

.top-special_bnr--link_img.sp {
  display: none;
}

@media screen and (max-width: 767px) {
  .top-special_bnr--link_img.sp {
    display: block;
    width: 100%;
  }
}

.top-special_bnr--link_img:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transform: translateY(2px);
}

.top-local_nav {
  background-color: #3AA4AF;
}

@media screen and (max-width: 767px) {
  .top-local_nav {
    display: none;
  }
}

.top-local_nav--inner {
  display: flex;
  flex-wrap: wrap;
  max-width: 765px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}

.top-local_nav--link {
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  text-decoration: none;
  line-height: 1.3;
  color: #fff;
  height: 50px;
  letter-spacing: -0.5px;
  box-sizing: border-box;
  border-right: 1px solid #fff;
  transition: 0.15s;
}

.top-local_nav--link:hover {
  background-color: #2d8089;
}

.top-local_nav--link:first-child {
  border-left: 1px solid #fff;
}

.box_button {
  border: 2px solid;
  max-width: 230px;
  width: 100%;
  text-align: center;
  height: 50px;
  line-height: 45px;
  position: relative;
  display: block;
  text-decoration: none;
  font-weight: bold;
  transition: 0.15s;
  font-size: 16px;
}

.box_button--right {
  position: absolute;
  right: 50px;
  top: 285px;
}

.box_button--lightblue {
  color: #3AA3AE;
}

.box_button:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: calc(50% - 10px);
  background: #3AA3AE;
}

.box_button:after {
  content: '';
  display: block;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  position: absolute;
  right: 28px;
  top: calc(50% - 4px);
  width: 6px;
  height: 6px;
}

@media screen and (max-width: 767px) {
  .box_button {
    display: none;
  }
}

.top-about {
  margin: 0 0 50px 0;
}

@media screen and (max-width: 767px) {
  .top-about {
    padding: 0 15px;
  }
}

.top-about--ttl {
  margin: 0 0 20px 0;
}

@media screen and (max-width: 767px) {
  .top-about--ttl {
    width: 230px;
    margin: 0 auto 20px;
  }
}

.top-about--ttl_img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.top-about--headline {
  color: #00528c;
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  font-weight: bold;
  margin: 0 0 35px 0;
}

@media screen and (max-width: 767px) {
  .top-about--headline {
    font-size: 14px;
    line-height: 1.8;
  }
}

.top-about--contents {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  max-width: 810px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}

.top-about--box {
  max-width: 360px;
  width: 100%;
  position: relative;
}

.top-about--box:first-child:before, .top-about--box:first-child:after {
  content: '';
  border-bottom: 3px solid #00528c;
  width: 65px;
  height: 3px;
  position: absolute;
  display: block;
  right: -80px;
  top: 125px;
  transform: rotate(45deg);
}

.top-about--box:first-child:after {
  transform: rotate(-45deg);
}

@media screen and (max-width: 767px) {
  .top-about--box {
    max-width: 100%;
  }
  .top-about--box:first-child {
    margin-bottom: 15px;
  }
}

.top-about--box_img {
  display: block;
  margin: 0 auto 15px;
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 767px) {
  .top-about--box_img {
    width: 150px;
  }
}

.top-about--box_description {
  line-height: 1.8;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .top-about--box_description {
    font-size: 14px;
  }
}

.top-target {
  background-color: #dddddd;
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .top-target {
    padding: 30px 15px;
  }
}

.top-target--ttl {
  color: #00528c;
  font-size: 36px;
  text-align: center;
  margin: 0 0 40px 0;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .top-target--ttl {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.3;
  }
}

.top-target--content {
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin: 0 auto 50px;
  padding: 30px 0;
}

@media screen and (max-width: 767px) {
  .top-target--content {
    padding: 15px;
    margin-bottom: 10px;
  }
}

.top-target--text {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 8px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .top-target--text {
    font-size: 16px;
  }
}

.top-target--headline {
  color: #3AA4AF;
  font-size: 22px;
  margin: 0 0 10px 0;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .top-target--headline {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.top-target--description {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .top-target--description {
    font-size: 14px;
  }
}

.top-target--1day_link {
  color: #3AA4AF;
  border: 2px solid #3AA4AF;
  max-width: 330px;
  width: 100%;
  text-align: center;
  line-height: 1.5;
  position: relative;
  display: block;
  font-weight: bold;
  margin: 0 auto;
  padding: 14px 24px 14px 0;
  transition: .15s;
}

@media screen and (max-width: 767px) {
  .top-target--1day_link {
    max-width: 320px;
  }
}

.top-target--1day_link:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #3AA4AF;
  position: absolute;
  top: calc(50% - 10px);
  right: 10px;
  transition: .15s;
}

.top-target--1day_link:after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(-135deg);
  position: absolute;
  top: calc(50% - 4px);
  right: 18px;
  transition: .15s;
}

.top-target--1day_link:hover {
  background-color: #3AA4AF;
  color: #fff;
}

.top-target--1day_link:hover:before {
  background-color: #fff;
}

.top-target--1day_link:hover:after {
  border-left-color: #3AA4AF;
  border-bottom-color: #3AA4AF;
}

.top-target--1day_link_label {
  padding: 2px 4px;
  background-color: #3AA4AF;
  color: #fff;
  display: inline-block;
  margin-right: 8px;
  border-radius: 4px;
}

@media screen and (max-width: 767px) {
  .top-target--1day_link_label {
    font-size: 12px;
  }
}

.top-target--link {
  display: block;
  margin: 0 auto;
  max-width: 500px;
  position: relative;
}

.top-target--link:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00528c;
}

.top-target--link:hover img {
  opacity: 0.8;
}

.top-target--link_img {
  transition: 0.15s;
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
}

.top-target--head {
  font-size: 18px;
  line-height: 1.5;
  margin-top: 32px;
  margin-bottom: 8px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .top-target--head {
    margin-top: 24px;
    font-size: 16px;
  }
}

.top-target_list--item {
  color: #3AA4AF;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 4px;
  text-align: center;
}

.top-target_list--item:first-child {
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  .top-target_list--item {
    font-size: 16px;
    text-align: left;
    padding-left: 1em;
    text-indent: -1em;
  }
}

.top-target_list--item-note {
  font-size: 14px;
  font-weight: 400;
  color: #333;
  margin-top: 12px;
}

.top-target_list--item-note:last-child {
  margin-top: 4px;
}

@media screen and (max-width: 767px) {
  .top-target_list--item-note {
    font-size: 12px;
    text-align: left;
    padding-left: 0;
    text-indent: 0;
  }
}

@media screen and (max-width: 767px) {
  .top-target_list--item-1day {
    padding-left: 0;
    text-indent: 0;
  }
}

.top-target_list--note {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  display: block;
}

@media screen and (max-width: 767px) {
  .top-target_list--note {
    font-size: 14px;
  }
}

.top-choose_course {
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .top-choose_course {
    padding: 30px 15px;
  }
}

.top-choose_course--sub_ttl {
  font-size: 21px;
  text-align: center;
  display: block;
  margin: 0 0 15px 0;
}

@media screen and (max-width: 767px) {
  .top-choose_course--sub_ttl {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.top-choose_course--ttl {
  color: #00528c;
  font-size: 36px;
  text-align: center;
  margin: 0 0 40px 0;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .top-choose_course--ttl {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.3;
  }
}

.top-choose_course--contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
}

.top-choose_course--box {
  max-width: 320px;
  width: 100%;
}

.top-choose_course--box-search .top-choose_course--headline {
  color: #39A06E;
}

.top-choose_course--box-search .top-choose_course--btn {
  border-color: #39A06E;
  color: #39A06E;
}

.top-choose_course--box-search .top-choose_course--btn:before {
  background-color: #39A06E;
}

.top-choose_course--box-search .top-choose_course--btn:hover {
  background-color: #39A06E;
  color: #fff;
}

.top-choose_course--box-break .top-choose_course--headline {
  color: #e28a35;
}

.top-choose_course--box-break .top-choose_course--btn {
  border-color: #e28a35;
  color: #e28a35;
}

.top-choose_course--box-break .top-choose_course--btn:before {
  background-color: #e28a35;
}

.top-choose_course--box-break .top-choose_course--btn:hover {
  background-color: #e28a35;
  color: #fff;
}

.top-choose_course--box-1day .top-choose_course--headline {
  color: #3AA4AF;
}

.top-choose_course--box-1day .top-choose_course--btn {
  border-color: #3AA4AF;
  color: #3AA4AF;
}

.top-choose_course--box-1day .top-choose_course--btn:before {
  background-color: #3AA4AF;
}

.top-choose_course--box-1day .top-choose_course--btn:hover {
  background-color: #3AA4AF;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .top-choose_course--box {
    max-width: 100%;
    margin-bottom: 30px;
  }
  .top-choose_course--box:last-child {
    margin-bottom: 0;
  }
}

.top-choose_course--pic {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 0 20px 0;
}

@media screen and (max-width: 767px) {
  .top-choose_course--pic {
    width: 100%;
  }
}

.top-choose_course--headline {
  font-size: 20px;
  line-height: 1.5;
  height: 60px;
  margin: 0 0 10px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .top-choose_course--headline {
    font-size: 16px;
    height: auto;
    display: block;
    margin-bottom: 10px;
  }
}

.top-choose_course--description {
  line-height: 1.8;
  font-size: 16px;
  margin: 0 0 20px 0;
  padding: 0 10px;
}

@media screen and (max-width: 767px) {
  .top-choose_course--description {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .top-choose_course--description {
    padding: 0 15px;
  }
}

.top-choose_course--btn {
  border: 2px solid red;
  max-width: 230px;
  width: 100%;
  text-align: center;
  height: 50px;
  line-height: 48px;
  position: relative;
  display: block;
  text-decoration: none;
  font-weight: bold;
  margin: 0 auto;
  transition: 0.15s;
}

.top-choose_course--btn:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: calc(50% - 10px);
}

.top-choose_course--btn:after {
  content: '';
  display: block;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(-135deg);
  position: absolute;
  right: 28px;
  top: calc(50% - 4px);
  width: 6px;
  height: 6px;
}

.top-step {
  background-color: #dddddd;
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .top-step {
    padding: 30px 15px;
  }
}

.top-step--ttl {
  color: #00528c;
  font-size: 36px;
  text-align: center;
  margin: 0 0 40px 0;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .top-step--ttl {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.3;
  }
}

@media screen and (max-width: 767px) {
  .top-step--headline_tmp {
    width: 200px;
  }
}

.top-step--graph {
  display: block;
  margin: 0 auto 20px;
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 767px) {
  .top-step--graph {
    display: none;
  }
}

.top-step--description {
  text-align: center;
  margin: 0 0 50px 0;
  line-height: 1.8;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .top-step--description {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .top-step--description {
    text-align: left;
    margin-bottom: 30px;
  }
}

.top-step--contents {
  display: block;
  padding: 0;
}

.top-step--marker {
  display: none;
}

@media screen and (max-width: 767px) {
  .top-step--marker {
    display: block;
    background-color: #3AA4AF;
    color: #fff;
    padding: 15px 10px;
    font-size: 12px;
    margin: 0 10px 0 0;
    width: 100px;
  }
}

.top-step--box {
  margin: 0 0 50px 0;
  position: relative;
  padding: 0 0 0 325px;
  min-height: 205px;
}

.top-step--box:after {
  content: '';
  display: block;
  background: url("/images/top-step-icon.png") no-repeat;
  width: 30px;
  height: 15px;
  background-size: cover;
  position: absolute;
  bottom: -30px;
  left: calc(50% - 15px);
}

@media screen and (max-width: 767px) {
  .top-step--box {
    padding-left: 0;
    background-color: #fff;
    border: 2px solid #3AA4AF;
    border-radius: 3px;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 767px) {
  .top-step--box-red {
    border: 2px solid #d38081;
  }
  .top-step--box-red .top-step--headline {
    background-color: #fff;
    color: #d38081;
    border-bottom-color: #d38081;
  }
  .top-step--box-red .top-step--marker {
    background-color: #d38081;
  }
}

.top-step--box_img {
  display: block;
  max-width: 100%;
  width: 300px;
  position: absolute;
  left: 0;
  top: 0;
}

@media screen and (max-width: 767px) {
  .top-step--box_img {
    width: calc(100% - 30px);
    position: static;
    display: block;
    margin: 0 auto 15px;
  }
}

.top-step--box_content {
  display: block;
  flex: 1;
  margin: 0 0 0 15px;
}

@media screen and (max-width: 767px) {
  .top-step--box_content {
    width: 100%;
    flex: inherit;
    margin-left: 0;
  }
}

.top-step--headline {
  background-color: #3AA4AF;
  color: #fff;
  border-radius: 3px;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 20px;
  margin: 0 0 15px 0;
}

@media screen and (max-width: 767px) {
  .top-step--headline {
    font-size: 16px;
    height: auto;
    line-height: 1;
    border-radius: 0;
    padding: 0;
    display: flex;
    background-color: #fff;
    color: #3AA4AF;
    align-items: center;
    border-bottom: 2px solid #3AA4AF;
  }
}

.top-step--headline-red {
  background-color: #d38081;
}

.top-step--box_description {
  line-height: 1.8;
  font-size: 16px;
  margin-bottom: 48px;
}

@media screen and (max-width: 767px) {
  .top-step--box_description {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .top-step--box_description {
    padding: 0 15px;
    margin: 0 0 40px 0;
  }
}

.top-step--link_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0 0 0;
  position: relative;
}

@media screen and (max-width: 767px) {
  .top-step--link_area {
    padding: 0 15px;
    margin-bottom: 15px;
  }
}

.top-step--link_area_heading {
  font-size: 16px;
  color: #333;
  position: absolute;
  top: -30px;
  left: 0;
}

@media screen and (max-width: 767px) {
  .top-step--link_area_heading {
    font-size: 14px;
    left: 15px;
  }
}

.top-step--link_1day {
  max-width: 210px;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 767px) {
  .top-step--link_1day {
    max-width: 100%;
  }
}

.top-step--link_1day_heading {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  display: block;
  position: absolute;
  top: -24px;
  left: 50px;
}

@media screen and (max-width: 767px) {
  .top-step--link_1day_heading {
    margin-bottom: 8px;
    position: relative;
    top: auto;
    left: auto;
  }
}

.top-step--link_btn {
  background-color: red;
  color: #fff;
  max-width: 210px;
  display: block;
  text-decoration: none;
  text-align: center;
  height: 50px;
  line-height: 50px;
  width: 100%;
  position: relative;
  transition: 0.15s;
}

.top-step--link_btn:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background-color: #fff;
  right: 6px;
  top: calc(50% - 10px);
}

@media screen and (max-width: 767px) {
  .top-step--link_btn:before {
    right: 16px;
  }
}

.top-step--link_btn:after {
  content: '';
  display: block;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(-135deg);
  position: absolute;
  right: 14px;
  top: calc(50% - 4px);
  width: 6px;
  height: 6px;
}

@media screen and (max-width: 767px) {
  .top-step--link_btn:after {
    right: 24px;
  }
}

@media screen and (max-width: 767px) {
  .top-step--link_btn {
    max-width: 100%;
    font-size: 12px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    margin-bottom: 16px;
  }
}

.top-step--link_btn-search {
  background-color: #39A06E;
}

.top-step--link_btn-search:hover {
  background-color: #2c7a54;
}

.top-step--link_btn-search:after {
  border-left-color: #39A06E;
  border-bottom-color: #39A06E;
}

.top-step--link_btn-break {
  background-color: #e28a35;
}

.top-step--link_btn-break:hover {
  background-color: #c7711d;
}

.top-step--link_btn-break:after {
  border-left-color: #e28a35;
  border-bottom-color: #e28a35;
}

.top-step--link_btn-1day {
  background-color: #3AA4AF;
}

.top-step--link_btn-1day:hover {
  background-color: #2d8089;
}

.top-step--link_btn-1day:after {
  border-left-color: #3AA4AF;
  border-bottom-color: #3AA4AF;
}

.top-step--icon {
  background-color: #fff;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 16px;
  color: #d38081;
  margin: 0 0 0 10px;
  position: relative;
  top: -2px;
}

@media screen and (max-width: 767px) {
  .top-step--icon {
    display: contents;
  }
}

.top-step--result {
  background-color: #008093;
  font-size: 30px;
  text-align: center;
  height: 68px;
  line-height: 68px;
  display: block;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
}

@media screen and (max-width: 767px) {
  .top-step--result {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}

.top-support {
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .top-support {
    padding: 30px 15px;
  }
}

.top-support--ttl {
  color: #00528c;
  font-size: 36px;
  text-align: center;
  margin: 0 0 40px 0;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .top-support--ttl {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.3;
  }
}

.top-support--content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 40px;
  row-gap: 26px;
  margin: 0 0 50px 0;
}

@media screen and (max-width: 767px) {
  .top-support--content {
    margin-bottom: 30px;
  }
}

.top-support--box {
  max-width: 300px;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .top-support--box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
    justify-content: space-between;
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .top-support--box_content {
    width: calc(100% - 110px);
  }
}

.top-support--box_img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto 15px;
}

@media screen and (max-width: 767px) {
  .top-support--box_img {
    width: 90px;
    margin: 0;
  }
}

.top-support--name {
  color: #3AA4AF;
  font-size: 16px;
  display: block;
  margin: 0 0 30px 0;
}

@media screen and (max-width: 767px) {
  .top-support--name {
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
  }
}

.top-support--popup {
  border: 2px solid #3AA4AF;
  border-radius: 5px;
  padding: 10px 16px;
  position: relative;
  min-height: 110px;
  text-align: left;
  line-height: 1.8;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .top-support--popup {
    font-size: 14px;
  }
}

.top-support--popup:after, .top-support--popup:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 20px 7.5px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  left: calc(50% - 7.5px);
  top: -20px;
}

.top-support--popup:before {
  border-width: 0 9.5px 24px 9.5px;
  top: -24px;
  left: calc(50% - 9.5px);
  border-color: transparent transparent #3AA4AF transparent;
}

@media screen and (max-width: 767px) {
  .top-support--popup {
    padding: 12px;
    text-align: left;
    min-height: auto;
  }
  .top-support--popup:after, .top-support--popup:before {
    display: none;
  }
  .top-support--popup:before {
    display: block;
    border-width: 0 7.5px 15px 7.5px;
    transform: rotate(-90deg);
    top: calc(50% - 7.5px);
    left: -15px;
  }
  .top-support--popup br {
    display: none;
  }
}

.top-about_course {
  background-color: #00528B;
  padding-top: 24px;
  padding-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .top-about_course {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    left: -15px;
    width: calc(100% + 30px);
  }
}

.top-about_course--inner {
  display: flex;
  justify-content: space-between;
  max-width: 932px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .top-about_course--inner {
    flex-direction: column;
  }
}

.top-about_course--link {
  position: relative;
  width: 300px;
  padding-top: 24px;
  padding-bottom: 20px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2247.721%22%20height%3D%2259%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20430%22%20d%3D%22M36.441%200h11.28L11.279%2059H0z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E"), url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2219%22%20viewBox%3D%220%200%2020%2019%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_1444%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%201444%22%20transform%3D%22translate%281196%20-8380%29%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_406%22%20data-name%3D%22%E3%83%91%E3%82%B9%20406%22%20d%3D%22M0%2C0%2C20%2C19H0Z%22%20transform%3D%22translate%28-1196%208380%29%22%20fill%3D%22%23399f6e%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_407%22%20data-name%3D%22%E3%83%91%E3%82%B9%20407%22%20d%3D%22M20%2C19%2C0%2C0H20Z%22%20transform%3D%22translate%28-1196%208380%29%22%20fill%3D%22%2352a880%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: left 8px top 0, 0 0;
  background-size: auto auto, 20px 19px;
}

.top-about_course--link-orange {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2247.721%22%20height%3D%2259%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20430%22%20d%3D%22M36.441%200h11.28L11.279%2059H0z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E"), url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2219%22%20viewBox%3D%220%200%2020%2019%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_1533%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%201533%22%20transform%3D%22translate%281196%20-8380%29%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_406%22%20data-name%3D%22%E3%83%91%E3%82%B9%20406%22%20d%3D%22M0%2C0%2C20%2C19H0Z%22%20transform%3D%22translate%28-1196%208380%29%22%20fill%3D%22%23e38e3d%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_407%22%20data-name%3D%22%E3%83%91%E3%82%B9%20407%22%20d%3D%22M20%2C19%2C0%2C0H20Z%22%20transform%3D%22translate%28-1196%208380%29%22%20fill%3D%22%23e99b52%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
}

.top-about_course--link-blue {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2247.721%22%20height%3D%2259%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20430%22%20d%3D%22M36.441%200h11.28L11.279%2059H0z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E"), url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2219%22%20viewBox%3D%220%200%2020%2019%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_1623%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%201623%22%20transform%3D%22translate%281196%20-8380%29%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_406%22%20data-name%3D%22%E3%83%91%E3%82%B9%20406%22%20d%3D%22M0%2C0%2C20%2C19H0Z%22%20transform%3D%22translate%28-1196%208380%29%22%20fill%3D%22%233ba4af%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_407%22%20data-name%3D%22%E3%83%91%E3%82%B9%20407%22%20d%3D%22M20%2C19%2C0%2C0H20Z%22%20transform%3D%22translate%28-1196%208380%29%22%20fill%3D%22%2343afba%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
}

.top-about_course--link:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  transition: 0.2s;
}

.top-about_course--link:hover:before {
  opacity: 0.15;
}

@media screen and (max-width: 767px) {
  .top-about_course--link {
    width: 100%;
    margin-bottom: 20px;
  }
  .top-about_course--link:last-child {
    margin-bottom: 0;
  }
}

.top-about_course--ttl {
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  position: relative;
  margin-bottom: 1em;
}

.top-about_course--ttl:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%3E%20%3Cg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20516%22%3E%20%3Cg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2081%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%2086%22%20d%3D%22M9.996%200a10%2010%200%201010%2010%2010%2010%200%2000-10-10zM8.33%2015.856L7.892%2014.1l4.316-4.3-4.316-4.3.438-1.754%206.07%206.056z%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
  right: 16px;
  top: calc(50% - 10px);
}

.top-about_course--lead {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.top-choice {
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .top-choice {
    padding: 30px 15px;
  }
}

.top-choice--lead {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 12px;
}

@media screen and (max-width: 767px) {
  .top-choice--lead {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.top-choice--ttl {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 55px;
  color: #00528c;
}

@media screen and (max-width: 767px) {
  .top-choice--ttl {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.top-choice_online {
  width: 1000px;
  margin: 0 auto 26px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .top-choice_online {
    width: 100%;
    display: block;
  }
}

.top-choice_online--pic {
  width: 348px;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 767px) {
  .top-choice_online--pic {
    width: 100%;
    object-fit: contain;
    display: block;
  }
}

.top-choice_online--detail {
  width: 652px;
  background: #02679B;
  padding: 24px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .top-choice_online--detail {
    width: 100%;
    padding: 16px;
  }
}

.top-choice_online--detail:before {
  content: '';
  display: block;
  width: 30px;
  height: 100%;
  position: absolute;
  background: #02679B;
  transform: skewX(-5deg);
  top: 0;
  left: -15px;
}

@media screen and (max-width: 767px) {
  .top-choice_online--detail:before {
    display: none;
  }
}

.top-choice_online--title {
  font-size: 28px;
  font-weight: 900;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

@media screen and (max-width: 767px) {
  .top-choice_online--title {
    font-size: 22px;
    display: block;
    margin-bottom: 8px;
  }
}

.top-choice_online--title_heading {
  font-size: 14px;
  font-weight: 600;
  color: #02679B;
  padding: 3px 8px;
  background: #fff;
  border-radius: 13px;
  margin-right: 8px;
}

@media screen and (max-width: 767px) {
  .top-choice_online--title_heading {
    margin-bottom: 5px;
  }
}

.top-choice_online--title_colored {
  color: #FFE622;
}

.top-choice_online--text {
  font-size: 16px;
  color: #fff;
  margin-bottom: 24px;
}

@media screen and (max-width: 767px) {
  .top-choice_online--text {
    font-size: 14px;
    margin-bottom: 18px;
  }
}

.top-choice_online--program {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  .top-choice_online--program {
    display: block;
  }
}

.top-choice_online_curriculum-5days {
  width: 428px;
}

@media screen and (max-width: 767px) {
  .top-choice_online_curriculum-5days {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .top-choice_online_curriculum-1day {
    display: inline-block;
    margin-top: 12px;
  }
}

.top-choice_online_curriculum_heading {
  display: flex;
  align-items: center;
}

.top-choice_online_curriculum_heading--label {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px 5px 0 0;
}

@media screen and (max-width: 767px) {
  .top-choice_online_curriculum_heading--label {
    font-size: 12px;
  }
}

.top-choice_online_curriculum_heading--label:nth-child(n+2) {
  margin-left: 8px;
}

.top-choice_online_curriculum_heading--label-explore {
  background: #39A06E;
}

.top-choice_online_curriculum_heading--label-practice {
  background: #e28a35;
}

.top-choice_online_curriculum_heading--label-1day {
  background: #3AA3AE;
}

.top-choice_online_curriculum_heading--note {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-left: 8px;
}

@media screen and (max-width: 767px) {
  .top-choice_online_curriculum_heading--note {
    font-size: 10px;
  }
}

.top-choice_online_curriculum_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  background: #fff;
}

@media screen and (max-width: 767px) {
  .top-choice_online_curriculum_list {
    padding: 6px 8px;
  }
}

.top-choice_online_curriculum_list--item {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  background: #2C9DD7;
}

@media screen and (max-width: 767px) {
  .top-choice_online_curriculum_list--item {
    font-size: 11px;
    padding: 2px 7px;
  }
}

.top-choice_online_curriculum_list--item_colored {
  color: #FFE622;
  margin-left: 3px;
}

.top-choice_online_notes {
  margin-top: 20px;
}

.top-choice_online_notes--text {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .top-choice_online_notes--text {
    font-size: 13px;
  }
}

.top-choice_online_notes_list {
  margin-top: 8px;
}

.top-choice_online_notes_list--item {
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .top-choice_online_notes_list--item {
    font-size: 13px;
  }
}

.top-choice_course {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto 40px;
  padding-left: 16px;
}

.top-choice_course:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .top-choice_course {
    padding-left: 0;
  }
  .top-choice_course:last-child {
    margin-bottom: 0;
  }
}

.top-choice_course_card {
  max-width: 360px;
  flex-shrink: 0;
  margin-right: 32px;
}

.orange .top-choice_course_card .top-choice_course_card--ttl {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2247.721%22%20height%3D%2259%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20430%22%20d%3D%22M36.441%200h11.28L11.279%2059H0z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E"), url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2219%22%3E%20%3Cg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20614%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20406%22%20d%3D%22M0%200l20%2019H0z%22%20fill%3D%22%23e38e3d%22%2F%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20407%22%20d%3D%22M20%2019L0%200h20z%22%20fill%3D%22%23e99b52%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
}

.blue .top-choice_course_card .top-choice_course_card--ttl {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2247.721%22%20height%3D%2259%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20430%22%20d%3D%22M36.441%200h11.28L11.279%2059H0z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E"), url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2219%22%3E%20%3Cg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20794%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20406%22%20d%3D%22M0%200l20%2019H0z%22%20fill%3D%22%233ba4af%22%2F%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20407%22%20d%3D%22M20%2019L0%200h20z%22%20fill%3D%22%2343afba%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
}

@media screen and (max-width: 767px) {
  .top-choice_course_card {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
    flex-shrink: 1;
  }
}

.top-choice_course_card--ttl {
  font-weight: bold;
  font-size: 22px;
  color: #fff;
  text-align: center;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2253.382%22%20height%3D%2266%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20408%22%20d%3D%22M40.765%200h12.617L12.618%2066H0z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E"), url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2219%22%3E%20%3Cg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20613%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20406%22%20d%3D%22M0%200l20%2019H0z%22%20fill%3D%22%23399f6e%22%2F%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20407%22%20d%3D%22M20%2019L0%200h20z%22%20fill%3D%22%2352a880%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-size: 53px 66px, 20px 19px;
  background-position: left 12px top 0, 0 0;
}

@media screen and (max-width: 767px) {
  .top-choice_course_card--ttl {
    font-size: 22px;
    background-size: 37px 46px, 20px 19px;
  }
}

.top-choice_course_card--img {
  display: block;
}

.top-choice_course_content {
  padding-top: 16px;
  padding-bottom: 16px;
  max-width: calc(640px - 48px);
  position: relative;
}

.orange .top-choice_course_content .top-choice_course_content--lead {
  color: #E18935;
}

.orange .top-choice_course_content .top-choice_course_content--date_label {
  background-color: #E18935;
}

.orange .top-choice_course_content .top-choice_course_content--link {
  color: #E18935;
  border: 2px solid #E18935;
}

.orange .top-choice_course_content .top-choice_course_content--link:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_551%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20551%22%20transform%3D%22translate(7588%20-11502)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_463%22%20data-name%3D%22%E3%83%91%E3%82%B9%20463%22%20d%3D%22M547.8%2C3139.181a10%2C10%2C0%2C1%2C0%2C10%2C10A10%2C10%2C0%2C0%2C0%2C547.8%2C3139.181Zm-1.666%2C15.856-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(-8125.804%208362.819)%22%20fill%3D%22%23e18935%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_464%22%20data-name%3D%22%E3%83%91%E3%82%B9%20464%22%20d%3D%22M546.138%2C3155.037l-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(-8125.804%208362.819)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
}

.orange .top-choice_course_content .top-choice_course_content--link:hover {
  background-color: #E18935;
  color: #fff;
}

.blue .top-choice_course_content .top-choice_course_content--lead {
  color: #3AA3AE;
}

.blue .top-choice_course_content .top-choice_course_content--date_label {
  background-color: #3AA3AE;
}

.blue .top-choice_course_content .top-choice_course_content--link {
  color: #3AA3AE;
  border: 2px solid #3AA3AE;
}

.blue .top-choice_course_content .top-choice_course_content--link:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_551%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20551%22%20transform%3D%22translate(7588%20-11502)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_463%22%20data-name%3D%22%E3%83%91%E3%82%B9%20463%22%20d%3D%22M547.8%2C3139.181a10%2C10%2C0%2C1%2C0%2C10%2C10A10%2C10%2C0%2C0%2C0%2C547.8%2C3139.181Zm-1.666%2C15.856-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(-8125.804%208362.819)%22%20fill%3D%22%233aa3ae%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_464%22%20data-name%3D%22%E3%83%91%E3%82%B9%20464%22%20d%3D%22M546.138%2C3155.037l-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(-8125.804%208362.819)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
}

.blue .top-choice_course_content .top-choice_course_content--link:hover {
  background-color: #3AA3AE;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .top-choice_course_content {
    padding-top: 0;
    padding-bottom: 0;
    max-width: 100%;
  }
}

.top-choice_course_content--lead {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.875;
  margin-bottom: 5px;
  color: #399F6E;
}

@media screen and (max-width: 767px) {
  .top-choice_course_content--lead {
    font-size: 18px;
  }
}

.top-choice_course_content--desc {
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 18px;
}

@media screen and (max-width: 767px) {
  .top-choice_course_content--desc {
    font-size: 14px;
  }
}

.top-choice_course_content--date {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 32px;
}

@media screen and (max-width: 767px) {
  .top-choice_course_content--date {
    margin-top: -8px;
    margin-bottom: calc(20px - 8px);
  }
}

.top-choice_course_content--date_label {
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  background-color: #399F6E;
  padding: 5px 24px 6px 16px;
  margin-right: 12px;
  clip-path: polygon(0 0, 100% 0, calc(100% - 12px) 100%, 0 100%);
}

@media screen and (max-width: 767px) {
  .top-choice_course_content--date_label {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.top-choice_course_content--date_text {
  font-weight: bold;
  font-size: 20px;
  padding-top: 3px;
}

@media screen and (max-width: 767px) {
  .top-choice_course_content--date_text {
    font-size: 16px;
    line-height: 1.5;
  }
}

.top-choice_course_content--date_text_note {
  display: block;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  margin-top: 8px;
}

@media screen and (max-width: 767px) {
  .top-choice_course_content--date_text_note {
    font-size: 14px;
    margin-top: 4px;
  }
}

.top-choice_course_content--link {
  display: block;
  max-width: 275px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding: 20px 0;
  color: #399F6E;
  border: 2px solid #399F6E;
  position: relative;
  transition: 0.2s;
}

.top-choice_course_content--link:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_551%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20551%22%20transform%3D%22translate(7588%20-11502)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_463%22%20data-name%3D%22%E3%83%91%E3%82%B9%20463%22%20d%3D%22M547.8%2C3139.181a10%2C10%2C0%2C1%2C0%2C10%2C10A10%2C10%2C0%2C0%2C0%2C547.8%2C3139.181Zm-1.666%2C15.856-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(-8125.804%208362.819)%22%20fill%3D%22%23399f6e%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_464%22%20data-name%3D%22%E3%83%91%E3%82%B9%20464%22%20d%3D%22M546.138%2C3155.037l-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(-8125.804%208362.819)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
  top: calc(50% - 10px);
  right: 16px;
}

.top-choice_course_content--link:hover {
  background-color: #399F6E;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .top-choice_course_content--link {
    max-width: 230px;
    margin: 0 auto;
    padding: 10px 0;
    font-size: 16px;
  }
}

.top-choice_course_content--online_label {
  position: absolute;
  left: 325px;
  bottom: 40px;
}

@media screen and (max-width: 767px) {
  .top-choice_course_content--online_label {
    position: relative;
    left: auto;
    bottom: auto;
    text-align: center;
    margin-top: 16px;
  }
}

.top-choice_course_content--online_label_text {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  background: #02679B;
  padding: 3px 16px;
  border: 1px solid #02679B;
  border-radius: 19px;
}

@media screen and (max-width: 767px) {
  .top-choice_course_content--online_label_text {
    font-size: 18px;
  }
}
