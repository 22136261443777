@charset 'UTF-8';
@import '../mixin';

@mixin ttl {
  color: #00528c;
  font-size: 36px;
  text-align: center;
  margin: 0 0 40px 0;
  line-height: 1.5;
  @include sp {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 1.3;
  }
}

@mixin description {
  line-height: 1.8;
  font-size: 16px;
  @include sp {
    font-size: 14px;
  }
}

@mixin content {
  padding: 80px 0;
  @include sp {
    padding: 30px 15px;
  }
}

.top-pdf_link {
  display: flex;
  position: fixed;
  top: 173px;
  right: 0;
  padding: 23px 12px 29px 12px;
  writing-mode: vertical-rl;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #3AA3AE;
  border-radius: 15px 0 0 15px;
  border: 1px solid #FFF;
  border-right: 0;
  z-index: 1;
  transition: 0.2s;
  &:after {
    position: relative;
    left: -3px;
    top: 12px;
    content: '';
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2215%22%3E%3Cpath%20data-name%3D%22Icon%20material-insert-drive-file%22%20d%3D%22M1.5%200A1.5%201.5%200%2000.008%201.5L0%2013.5A1.5%201.5%200%20001.492%2015H10.5a1.5%201.5%200%20001.5-1.5v-9L7.5%200zm5.25%205.25V1.125l4.125%204.125z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
    display: block;
    width: 12px;
    height: 15px;
  }
  &:hover {
    background-color: darken($color-main,10%);
  }
  @include sp {
    display: none;
  }
}

.top-main_visual {
  position: relative;
  display: block;
  margin-top: 94px;
  padding-bottom: 60px;
  @include sp {
    margin-top: 70px;
    border-top: 0;
    height: auto;
    padding-bottom: 0;
  }
  /*
  &-1 {
    .top-main_visual--bg {
      background-image: url('/images/top-main-visual-01.jpg');
      @include sp {
        background-image: url('/images/top-main-visual-01-sp.jpg');
      }
    }
  }
  &-2 {
    .top-main_visual--bg {
      background-image: url('/images/top-main-visual-02.jpg');
      @include sp {
        background-image: url('/images/top-main-visual-02-sp.jpg');
      }
    }
  }
  &-3 {
    .top-main_visual--bg {
      background-image: url('/images/top-main-visual-03.jpg');
      @include sp {
        background-image: url('/images/top-main-visual-03-sp.jpg');
      }
    }
  }
  &--4 {
    position: relative;
    display: block;
  }*/
  &--bg {
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    position: absolute;
    height: 100%;
    @include sp {
      background-position: center top;
      background-size: cover;
      z-index: 1;
      position: relative;
      height: 0;
    }
    /*
    &-triangle {
      background-color: #3AA3AE;
      background-image: url("/images/top-bg-triangle.png");
      background-repeat: repeat;
    }
    */
    &-border {
      background-image: url("/images/bg-top-main_visual.png");
      background-repeat: repeat;
      z-index: -1;
    }
  }
  &--inner {
    width: 980px;
    margin: 0 auto;
    position: static;
    @include sp {
      width: 100%;
    }
    /*&-sp_thin { 適職探索、就活実践コースが中止のためメインビジュアルの高さを調整
      @include sp {
        padding: 0 0 3px;
      }
    }*/
  }
  &--wrap {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 0;
    position: relative;
    @include sp {
      display: block;
      background-image: url("/images/bg-top-main_visual.png");
      background-repeat: repeat;
      padding: 30px 15px 40px;
    }
  }
  &--text_area {
    padding: 56px 0 0;
    width: 390px;
    @include sp {
      width: 100%;
      padding: 0;
      text-align: center;
    }
  }
  &--headline {
    margin: 0 0 22px;
    @include sp {
      margin: 0 0 16px;
    }
  }
  &--support {
    margin: 0 0 20px -16px;
  }
  &--box {
    @include sp {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0 0 24px;
    }
  }
  &--img_program {
    @include sp {
      &:first-child {
        width: 48%;
      }
      &:last-child {
        width: 52%;
      }
    }
  }
  &--corse_box {
    width: 684px;
    margin: 0 auto;
    position: absolute;
    bottom: 135px;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.single {
      justify-content: center;
    }

    @include sp {
      width: calc(100% - 30px);
      /*margin: 82px auto 0;*/
      position: relative;
      top: 15px;
    }
  }
  &--button_area {
    position: relative;
    &:before {
      content: '';
      width: 15px;
      height: 15px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      right: -24px;
      top: calc(50% - 10px);
    }
    &:after {
      content: '';
      width: 5px;
      height: 5px;
      border-top: 1px solid #3AA3AE;
      border-right: 1px solid #3AA3AE;
      transform: rotate(45deg);
      position: absolute;
      right: -18px;
      top: calc(50% - 5px);
    }
  }
  &--link {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 16px 40px 16px 16px;
    margin: 0 0 0 -40px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      transform: skewX(-12deg);
      background: #3AA3AE;
      border: 1px solid #fff;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .16);
      transition: .15s;
    }
    &:hover {
      &:before {
        background: #2d8089;
      }
    }
    @include sp {
      margin: 0;
    }
  }
  &--1day {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 12px;
    @include sp {
      position: relative;
      left: auto;
      bottom: auto;
      margin-top: 25px;
    }
  }
  &--1day_link {
    display: inline-block;
    @include sp {
      display: block;
    }
    &:hover {
      .top-main_visual--1day_pic {
        box-shadow: 0 0 0 rgba(0,0,0,0);
        transform: translateY(2px);
      }
    }
  }
  &--1day_pic {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    transition: .15s;
    @include sp {
      margin: 0 auto;
    }
  }
  &--online_anchor {
    position: absolute;
    left: 48px;
    bottom: -30px;
    width: 477px;
    height: 56px;
    border: 1px solid #fff;
    border-radius: 28px;
    background: #02679B;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    font-size: 20px;
    font-weight: $fw-black;
    color: #fff;
    padding: 3px 44px 3px 3px;
    transition: .15s;
    @include sp {
      width: 100%;
      position: relative;
      left: auto;
      bottom: auto;
      font-size: 14px;
      height: 43px;
      margin: 24px auto 0;
      padding-right: 35px;
    }
    &:hover {
      transform: translateY(5px);
    }
    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_523%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20523%22%20transform%3D%22translate(3159.181%20-537.804)%20rotate(90)%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_81%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2081%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_86%22%20data-name%3D%22%E3%83%91%E3%82%B9%2086%22%20d%3D%22M547.8%2C3139.181a10%2C10%2C0%2C1%2C0%2C10%2C10A10%2C10%2C0%2C0%2C0%2C547.8%2C3139.181Zm-1.666%2C15.856-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(0%200)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
      background-size: contain;
      position: absolute;
      top: calc(50% - 10px);
      right: 16px;
      @include sp {
        width: 14px;
        height: 14px;
        top: calc(50% - 7px);
        right: 13px;
      }
    }
  }
  &--online_anchor_heading {
    font-size: 14px;
    font-weight: $fw-bold;
    color: #02679B;
    line-height: 1.1;
    display: flex;
    width: 48px;
    height: 48px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    margin-right: 8px;
    @include sp {
      font-size: 11px;
      width: 37px;
      height: 37px;
    }
  }
  &--online_anchor_text {
    text-align: left;
    margin-left: 3em;
  }
  &--online_anchor_colored {
    color: #FFE622;
  }
}

.top-special_bnr {
  display: block;
  background-color: #b7b7b7;
  padding: 15px 0;

  &--link {
    display: block;
  }

  &--link_img {
    max-width: 100%;
    display: block;
    height: auto;
    margin: 0 auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    transition: .15s;

    &.pc {
      @include sp {
        display: none;
      }
    }

    &.sp {
      display: none;
      @include sp {
        display: block;
        width: 100%;
      }
    }

    &:hover {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      transform: translateY(2px);
    }
  }
}

.top-local_nav {
  background-color: $color-main;
  @include sp {
    display: none;
  }

  &--inner {
    display: flex;
    flex-wrap: wrap;
    max-width: 765px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
  }

  &--link {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    text-decoration: none;
    line-height: 1.3;
    color: #fff;
    height: 50px;
    letter-spacing: -0.5px;
    box-sizing: border-box;
    border-right: 1px solid #fff;
    transition: 0.15s;

    &:hover {
      background-color: darken($color-main, 10%);
    }

    &:first-child {
      border-left: 1px solid #fff;
    }
  }
}

.box_button {
  border: 2px solid;
  max-width: 230px;
  width: 100%;
  text-align: center;
  height: 50px;
  line-height: 45px;
  position: relative;
  display: block;
  text-decoration: none;
  font-weight: bold;
  transition: 0.15s;
  font-size: 16px;

  &--right {
    position: absolute;
    right: 50px;
    top: 285px;
  }

  &--lightblue {
    color: #3AA3AE;
  }

  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: calc(50% - 10px);
    background: #3AA3AE;
  }

  &:after {
    content: '';
    display: block;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    position: absolute;
    right: 28px;
    top: calc(50% - 4px);
    width: 6px;
    height: 6px;
  }

  @include sp {
    display: none;
  }
}

.top-about {
  margin: 0 0 50px 0;
  @include sp {
    padding: 0 15px;
  }

  &--ttl {
    margin: 0 0 20px 0;
    @include sp {
      width: 230px;
      margin: 0 auto 20px;
    }
  }

  &--ttl_img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }

  &--headline {
    color: $color-blue;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    font-weight: bold;
    margin: 0 0 35px 0;
    @include sp {
      font-size: 14px;
      line-height: 1.8;
    }
  }

  &--contents {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    max-width: 810px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
  }

  &--box {
    max-width: 360px;
    width: 100%;
    position: relative;

    &:first-child {
      &:before {
        content: '';
        border-bottom: 3px solid $color-blue;
        width: 65px;
        height: 3px;
        position: absolute;
        display: block;
        right: -80px;
        top: 125px;
        transform: rotate(45deg);
      }

      &:after {
        @extend .top-about--box:before;
        transform: rotate(-45deg);
      }
    }

    @include sp {
      max-width: 100%;
      &:first-child {
        margin-bottom: 15px;
      }
    }
  }

  &--box_img {
    display: block;
    margin: 0 auto 15px;
    max-width: 100%;
    height: auto;
    @include sp {
      width: 150px;
    }
  }

  &--box_description {
    @include description;
  }
}

.top-target {
  background-color: #dddddd;
  @include content;

  &--ttl {
    @include ttl;
  }

  &--content {
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 800px;
    margin: 0 auto 50px;
    padding: 30px 0;
    @include sp {
      padding: 15px;
      margin-bottom: 10px;
    }
  }

  &--text {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 8px;
    text-align: center;
    @include sp {
      font-size: 16px;
    }
  }

  &--headline {
    color: $color-main;
    font-size: 22px;
    margin: 0 0 10px 0;
    line-height: 1.8;
    @include sp {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  &--description {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    @include sp {
      font-size: 14px;
    }
  }

  &--1day_link {
    color: $color-main;
    border: 2px solid $color-main;
    max-width: 330px;
    width: 100%;
    text-align: center;
    line-height: 1.5;
    position: relative;
    display: block;
    font-weight: bold;
    margin: 0 auto;
    padding: 14px 24px 14px 0;
    transition: .15s;
    @include sp {
      max-width: 320px;
    }
    &:before {
      content: '';
      display:  block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $color-main;
      position: absolute;
      top: calc(50% - 10px);
      right: 10px;
      transition: .15s;
    }
    &:after {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: rotate(-135deg);
      position: absolute;
      top: calc(50% - 4px);
      right: 18px;
      transition: .15s;
    }
    &:hover {
      background-color: $color-main;
      color: #fff;
      &:before {
        background-color: #fff;
      }
      &:after {
        border-left-color: $color-main;
        border-bottom-color: $color-main;
      }
    }
  }
  &--1day_link_label {
    padding: 2px 4px;
    background-color: $color-main;
    color: #fff;
    display: inline-block;
    margin-right: 8px;
    border-radius: 4px;
    @include sp {
      font-size: 12px;
    }
  }

  &--link {
    display: block;
    margin: 0 auto;
    max-width: 500px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $color-blue;
    }

    &:hover {
      img {
        opacity: 0.8;
      }
    }
  }

  &--link_img {
    transition: 0.15s;
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
  }
  &--head {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 32px;
    margin-bottom: 8px;
    text-align: center;
    @include sp {
      margin-top: 24px;
      font-size: 16px;
    }
  }
}

.top-target_list {
  &--item {
    color: $color-main;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.5;
    margin-top: 4px;
    text-align: center;
    &:first-child {
      margin-top: 0;
    }
    @include sp {
      font-size: 16px;
      text-align: left;
      padding-left: 1em;
      text-indent: -1em;
    }
    &-note {
      font-size: 14px;
      font-weight: 400;
      color: #333;
      margin-top: 12px;
      &:last-child {
        margin-top: 4px;
      }
      @include sp {
        font-size: 12px;
        text-align: left;
        padding-left: 0;
        text-indent: 0;
      }
    }
    &-1day {
      @include sp {
        padding-left: 0;
        text-indent: 0;
      }
    }
  }
  &--note {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    display: block;
    @include sp {
      font-size: 14px;
    }
  }
}

.top-choose_course {
  @include content;

  &--sub_ttl {
    font-size: 21px;
    text-align: center;
    display: block;
    margin: 0 0 15px 0;
    @include sp {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  &--ttl {
    @include ttl;
  }

  &--contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
  }

  &--box {
    max-width: 320px;
    width: 100%;

    &-search {
      .top-choose_course {
        &--headline {
          color: $color-green;
        }

        &--btn {
          border-color: $color-green;
          color: $color-green;

          &:before {
            background-color: $color-green;
          }

          &:hover {
            background-color: $color-green;
            color: #fff;
          }
        }
      }
    }

    &-break {
      .top-choose_course {
        &--headline {
          color: $color-orange;
        }

        &--btn {
          border-color: $color-orange;
          color: $color-orange;

          &:before {
            background-color: $color-orange;
          }

          &:hover {
            background-color: $color-orange;
            color: #fff;
          }
        }
      }
    }

    &-1day {
      .top-choose_course {
        &--headline {
          color: $color-main;
        }

        &--btn {
          border-color: $color-main;
          color: $color-main;

          &:before {
            background-color: $color-main;
          }

          &:hover {
            background-color: $color-main;
            color: #fff;
          }
        }
      }
    }

    @include sp {
      max-width: 100%;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--pic {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 0 20px 0;
    @include sp {
      width: 100%;
    }
  }

  &--headline {
    font-size: 20px;
    line-height: 1.5;
    height: 60px;
    margin: 0 0 10px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @include sp {
      font-size: 16px;
      height: auto;
      display: block;
      margin-bottom: 10px;
    }
  }

  &--description {
    @include description;
    margin: 0 0 20px 0;
    padding: 0 10px;
    @include sp {
      padding: 0 15px;
    }
  }

  &--btn {
    border: 2px solid red;
    max-width: 230px;
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 48px;
    position: relative;
    display: block;
    text-decoration: none;
    font-weight: bold;
    margin: 0 auto;
    transition: 0.15s;

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      right: 20px;
      top: calc(50% - 10px);
    }

    &:after {
      content: '';
      display: block;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: rotate(-135deg);
      position: absolute;
      right: 28px;
      top: calc(50% - 4px);
      width: 6px;
      height: 6px;
    }
  }
}

.top-step {
  background-color: #dddddd;
  @include content;

  &--ttl {
    @include ttl;
  }

  &--headline_tmp {
    @include sp {
      width: 200px;
    }
  }

  &--graph {
    display: block;
    margin: 0 auto 20px;
    max-width: 100%;
    height: auto;
    @include sp {
      display: none;
    }
  }

  &--description {
    text-align: center;
    margin: 0 0 50px 0;
    @include description;
    @include sp {
      text-align: left;
      margin-bottom: 30px;
    }
  }

  &--contents {
    display: block;
    padding: 0;
  }

  &--marker {
    display: none;
    @include sp {
      display: block;
      background-color: $color-main;
      color: #fff;
      padding: 15px 10px;
      font-size: 12px;
      margin: 0 10px 0 0;
      width: 100px;
    }
  }

  &--box {
    margin: 0 0 50px 0;
    position: relative;
    padding: 0 0 0 325px;
    min-height: 205px;

    &:after {
      content: '';
      display: block;
      background: url('/images/top-step-icon.png') no-repeat;
      width: 30px;
      height: 15px;
      background-size: cover;
      position: absolute;
      bottom: -30px;
      left: calc(50% - 15px);
    }

    @include sp {
      padding-left: 0;
      background-color: #fff;
      border: 2px solid $color-main;
      border-radius: 3px;
      box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
    }

    &-red {
      @include sp {
        border: 2px solid #d38081;
        .top-step {
          &--headline {
            background-color: #fff;
            color: #d38081;
            border-bottom-color: #d38081;
          }

          &--marker {
            background-color: #d38081;
          }
        }
      }
    }
  }

  &--box_img {
    display: block;
    max-width: 100%;
    width: 300px;
    position: absolute;
    left: 0;
    top: 0;
    @include sp {
      width: calc(100% - 30px);
      position: static;
      display: block;
      margin: 0 auto 15px;
    }
  }

  &--box_content {
    display: block;
    flex: 1;
    margin: 0 0 0 15px;
    @include sp {
      width: 100%;
      flex: inherit;
      margin-left: 0;
    }
  }

  &--headline {
    background-color: $color-main;
    color: #fff;
    border-radius: 3px;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 20px;
    margin: 0 0 15px 0;
    @include sp {
      font-size: 16px;
      height: auto;
      line-height: 1;
      border-radius: 0;
      padding: 0;
      display: flex;
      background-color: #fff;
      color: $color-main;
      align-items: center;
      border-bottom: 2px solid $color-main;
    }

    &-red {
      background-color: #d38081;
    }
  }

  &--box_description {
    @include description;
    margin-bottom: 48px;
    @include sp {
      padding: 0 15px;
      margin: 0 0 40px 0;
    }
  }

  &--link_area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px 0 0 0;
    position: relative;
    @include sp {
      padding: 0 15px;
      margin-bottom: 15px;
    }
  }

  &--link_area_heading {
    font-size: 16px;
    color: #333;
    position: absolute;
    top: -30px;
    left: 0;
    @include sp {
      font-size: 14px;
      left: 15px;
    }
  }

  &--link_1day {
    max-width: 210px;
    width: 100%;
    position: relative;
    @include sp {
      max-width: 100%;
    }
  }

  &--link_1day_heading {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    display: block;
    position: absolute;
    top: -24px;
    left: 50px;
    @include sp {
      margin-bottom: 8px;
      position: relative;
      top: auto;
      left: auto;
    }
  }

  &--link_btn {
    background-color: red;
    color: #fff;
    max-width: 210px;
    display: block;
    text-decoration: none;
    text-align: center;
    height: 50px;
    line-height: 50px;
    width: 100%;
    position: relative;
    transition: 0.15s;

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      background-color: #fff;
      right: 6px;
      top: calc(50% - 10px);
      @include sp {
        right: 16px;
      }
    }

    &:after {
      content: '';
      display: block;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: rotate(-135deg);
      position: absolute;
      right: 14px;
      top: calc(50% - 4px);
      width: 6px;
      height: 6px;
      @include sp {
        right: 24px;
      }
    }

    @include sp {
      max-width: 100%;
      font-size: 12px;
      font-weight: bold;
      height: 40px;
      line-height: 40px;
      margin-bottom: 16px;
    }

    &-search {
      background-color: $color-green;

      &:hover {
        background-color: darken($color-green, 10%);
      }

      &:after {
        border-left-color: $color-green;
        border-bottom-color: $color-green;
      }
    }

    &-break {
      background-color: $color-orange;

      &:hover {
        background-color: darken($color-orange, 10%);
      }

      &:after {
        border-left-color: $color-orange;
        border-bottom-color: $color-orange;
      }
    }

    &-1day {
      background-color: $color-main;

      &:hover {
        background-color: darken($color-main, 10%);
      }

      &:after {
        border-left-color: $color-main;
        border-bottom-color: $color-main;
      }
    }
  }

  &--icon {
    background-color: #fff;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 16px;
    color: #d38081;
    margin: 0 0 0 10px;
    position: relative;
    top: -2px;
    @include sp {
      display: contents;
    }
  }

  &--result {
    background-color: #008093;
    font-size: 30px;
    text-align: center;
    height: 68px;
    line-height: 68px;
    display: block;
    color: #fff;
    font-weight: bold;
    border-radius: 3px;
    @include sp {
      height: 50px;
      line-height: 50px;
      font-size: 20px;
    }
  }
}

.top-support {
  @include content;

  &--ttl {
    @include ttl;
  }

  &--content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 40px;
    row-gap: 26px;
    margin: 0 0 50px 0;
    @include sp {
      margin-bottom: 30px;
    }
  }

  &--box {
    max-width: 300px;
    width: 100%;
    text-align: center;
    @include sp {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      max-width: 100%;
      justify-content: space-between;
      margin-left: 0;
    }
  }

  &--box_content {
    @include sp {
      width: calc(100% - 110px);
    }
  }

  &--box_img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto 15px;
    @include sp {
      width: 90px;
      margin: 0;
    }
  }

  &--name {
    color: $color-main;
    font-size: 16px;
    display: block;
    margin: 0 0 30px 0;
    @include sp {
      font-size: 14px;
      text-align: left;
      margin-bottom: 10px;
    }
  }

  &--popup {
    border: 2px solid $color-main;
    border-radius: 5px;
    padding: 10px 16px;
    position: relative;
    min-height: 110px;
    text-align: left;
    @include description;

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7.5px 20px 7.5px;
      border-color: transparent transparent #fff transparent;
      position: absolute;
      left: calc(50% - 7.5px);
      top: -20px;
    }

    &:before {
      @extend .top-support--popup:after;
      border-width: 0 9.5px 24px 9.5px;
      top: -24px;
      left: calc(50% - 9.5px);
      border-color: transparent transparent $color-main transparent;
    }

    @include sp {
      padding: 12px;
      text-align: left;
      min-height: auto;
      &:after {
        display: none;
      }
      &:before {
        display: block;
        border-width: 0 7.5px 15px 7.5px;
        transform: rotate(-90deg);
        top: calc(50% - 7.5px);
        left: -15px;
      }
      br {
        display: none;
      }
    }
  }
}
.top-about_course {
  background-color: #00528B;
  padding-top: 24px;
  padding-bottom: 40px;
  @include sp {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    left: -15px;
    width: calc(100% + 30px);
  }
  &--inner {
    display: flex;
    justify-content: space-between;
    max-width: 932px;
    margin: 0 auto;
    @include sp {
      flex-direction: column;
    }
  }
  &--link {
    position: relative;
    width: 300px;
    padding-top: 24px;
    padding-bottom: 20px;
    background-image:
            // 斜線
            url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2247.721%22%20height%3D%2259%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20430%22%20d%3D%22M36.441%200h11.28L11.279%2059H0z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E'),
            // タイル
            url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2219%22%20viewBox%3D%220%200%2020%2019%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_1444%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%201444%22%20transform%3D%22translate%281196%20-8380%29%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_406%22%20data-name%3D%22%E3%83%91%E3%82%B9%20406%22%20d%3D%22M0%2C0%2C20%2C19H0Z%22%20transform%3D%22translate%28-1196%208380%29%22%20fill%3D%22%23399f6e%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_407%22%20data-name%3D%22%E3%83%91%E3%82%B9%20407%22%20d%3D%22M20%2C19%2C0%2C0H20Z%22%20transform%3D%22translate%28-1196%208380%29%22%20fill%3D%22%2352a880%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: left 8px top 0, 0 0;
    background-size: auto auto, 20px 19px;
    &-orange {
      background-image:
              url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2247.721%22%20height%3D%2259%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20430%22%20d%3D%22M36.441%200h11.28L11.279%2059H0z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E'),
              url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2219%22%20viewBox%3D%220%200%2020%2019%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_1533%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%201533%22%20transform%3D%22translate%281196%20-8380%29%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_406%22%20data-name%3D%22%E3%83%91%E3%82%B9%20406%22%20d%3D%22M0%2C0%2C20%2C19H0Z%22%20transform%3D%22translate%28-1196%208380%29%22%20fill%3D%22%23e38e3d%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_407%22%20data-name%3D%22%E3%83%91%E3%82%B9%20407%22%20d%3D%22M20%2C19%2C0%2C0H20Z%22%20transform%3D%22translate%28-1196%208380%29%22%20fill%3D%22%23e99b52%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');

    }
    &-blue {
      background-image:
              url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2247.721%22%20height%3D%2259%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20430%22%20d%3D%22M36.441%200h11.28L11.279%2059H0z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E'),
              url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2219%22%20viewBox%3D%220%200%2020%2019%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_1623%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%201623%22%20transform%3D%22translate%281196%20-8380%29%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_406%22%20data-name%3D%22%E3%83%91%E3%82%B9%20406%22%20d%3D%22M0%2C0%2C20%2C19H0Z%22%20transform%3D%22translate%28-1196%208380%29%22%20fill%3D%22%233ba4af%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_407%22%20data-name%3D%22%E3%83%91%E3%82%B9%20407%22%20d%3D%22M20%2C19%2C0%2C0H20Z%22%20transform%3D%22translate%28-1196%208380%29%22%20fill%3D%22%2343afba%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #fff;
      opacity: 0;
      transition: 0.2s;
    }
    &:hover:before {
      opacity: 0.15;
    }
    @include sp {
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &--ttl {
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    position: relative;
    margin-bottom: 1em;
    &:after {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%3E%20%3Cg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20516%22%3E%20%3Cg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2081%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%2086%22%20d%3D%22M9.996%200a10%2010%200%201010%2010%2010%2010%200%2000-10-10zM8.33%2015.856L7.892%2014.1l4.316-4.3-4.316-4.3.438-1.754%206.07%206.056z%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
      right: 16px;
      top: calc(50% - 10px);
    }
  }
  &--lead {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }
}
.top-choice {
  padding: 80px 0;
  @include sp {
    padding: 30px 15px;
  }
  &--lead {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 12px;
    @include sp {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  &--ttl {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 55px;
    color: #00528c;
    @include sp {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
}

.top-choice_online {
  width: 1000px;
  margin: 0 auto 26px;
  display: flex;
  justify-content: space-between;
  @include sp {
    width: 100%;
    display: block;
  }
  &--pic {
    width: 348px;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    @include sp {
      width: 100%;
      object-fit: contain;
      display: block;
    }
  }
  &--detail {
    width: 652px;
    background: #02679B;
    padding: 24px;
    position: relative;
    @include sp {
      width: 100%;
      padding: 16px;
    }
    &:before {
      content: '';
      display: block;
      width: 30px;
      height: 100%;
      position: absolute;
      background: #02679B;
      transform: skewX(-5deg);
      top: 0;
      left: -15px;
      @include sp {
        display: none;
      }
    }
  }
  &--title {
    font-size: 28px;
    font-weight: $fw-black;
    color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    @include sp {
      font-size: 22px;
      display: block;
      margin-bottom: 8px;
    }
  }
  &--title_heading {
    font-size: 14px;
    font-weight: $fw-bold;
    color: #02679B;
    padding: 3px 8px;
    background: #fff;
    border-radius: 13px;
    margin-right: 8px;
    @include sp {
      margin-bottom: 5px;
    }
  }
  &--title_colored {
    color: #FFE622;
  }
  &--text {
    font-size: 16px;
    color: #fff;
    margin-bottom: 24px;
    @include sp {
      font-size: 14px;
      margin-bottom: 18px;
    }
  }
  &--program {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include sp {
      display: block;
    }
  }
}

.top-choice_online_curriculum {
  &-5days {
    width: 428px;
    @include sp {
      width: 100%;
    }
  }
  &-1day {
    @include sp {
      display: inline-block;
      margin-top: 12px;
    }
  }
}

.top-choice_online_curriculum_heading {
  display: flex;
  align-items: center;
  &--label {
    font-size: 16px;
    font-weight: $fw-bold;
    color: #fff;
    padding: 2px 10px;
    border-radius: 5px 5px 0 0;
    @include sp {
      font-size: 12px;
    }
    &:nth-child(n+2) {
      margin-left: 8px;
    }
    &-explore {
      background: $color-green;
    }
    &-practice {
      background: $color-orange;
    }
    &-1day {
      background: #3AA3AE;
    }
  }
  &--note {
    font-size: 14px;
    font-weight: $fw-bold;
    color: #fff;
    margin-left: 8px;
    @include sp {
      font-size: 10px;
    }
  }
}

.top-choice_online_curriculum_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  background: #fff;
  @include sp {
    padding: 6px 8px;
  }
  &--item {
    font-size: 16px;
    font-weight: $fw-bold;
    color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
    background: #2C9DD7;
    @include sp {
      font-size: 11px;
      padding: 2px 7px;
    }
  }
  &--item_colored {
    color: #FFE622;
    margin-left: 3px;
  }
}

.top-choice_online_notes {
  margin-top: 20px;
  &--text {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    color: #fff;
    @include sp {
      font-size: 13px;
    }
  }
}

.top-choice_online_notes_list {
  margin-top: 8px;
  &--item {
    font-size: 14px;
    line-height: 1.5;
    color: #fff;
    @include sp {
      font-size: 13px;
    }
  }
}

.top-choice_course {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto 40px;
  padding-left: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  @include sp {
    padding-left: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.top-choice_course_card {
  max-width: 360px;
  flex-shrink: 0;
  margin-right: 32px;
  $this: &;
  .orange & {
    #{$this}--ttl {
      background-image:
              url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2247.721%22%20height%3D%2259%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20430%22%20d%3D%22M36.441%200h11.28L11.279%2059H0z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E'),
              url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2219%22%3E%20%3Cg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20614%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20406%22%20d%3D%22M0%200l20%2019H0z%22%20fill%3D%22%23e38e3d%22%2F%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20407%22%20d%3D%22M20%2019L0%200h20z%22%20fill%3D%22%23e99b52%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
    }
  }
  .blue & {
    #{$this}--ttl {
      background-image:
              url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2247.721%22%20height%3D%2259%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20430%22%20d%3D%22M36.441%200h11.28L11.279%2059H0z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E'),
              url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2219%22%3E%20%3Cg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20794%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20406%22%20d%3D%22M0%200l20%2019H0z%22%20fill%3D%22%233ba4af%22%2F%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20407%22%20d%3D%22M20%2019L0%200h20z%22%20fill%3D%22%2343afba%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
    }
  }
  @include sp {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
    flex-shrink: 1;
  }
  &--ttl {
    font-weight: bold;
    font-size: 22px;
    color: #fff;
    text-align: center;
    padding-top: 0.9em;
    padding-bottom: 0.9em;
    background-image:
            // 斜線
            url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2253.382%22%20height%3D%2266%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20408%22%20d%3D%22M40.765%200h12.617L12.618%2066H0z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E'),
            // タイル
            url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2219%22%3E%20%3Cg%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20613%22%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20406%22%20d%3D%22M0%200l20%2019H0z%22%20fill%3D%22%23399f6e%22%2F%3E%20%3Cpath%20data-name%3D%22%E3%83%91%E3%82%B9%20407%22%20d%3D%22M20%2019L0%200h20z%22%20fill%3D%22%2352a880%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-size: 53px 66px, 20px 19px;
    background-position: left 12px top 0, 0 0;
    @include sp {
      font-size: 22px;
      background-size: 37px 46px, 20px 19px;
    }
  }
  &--img {
    display: block;
  }
}
.top-choice_course_content {
  padding-top: 16px;
  padding-bottom: 16px;
  max-width: calc(640px - 48px);
  position: relative;
  $this: &;
  .orange & {
    #{$this}--lead {
      color: #E18935;
    }
    #{$this}--date_label {
      background-color: #E18935;
    }
    #{$this}--link {
      color: #E18935;
      border: 2px solid #E18935;
      &:after {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_551%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20551%22%20transform%3D%22translate(7588%20-11502)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_463%22%20data-name%3D%22%E3%83%91%E3%82%B9%20463%22%20d%3D%22M547.8%2C3139.181a10%2C10%2C0%2C1%2C0%2C10%2C10A10%2C10%2C0%2C0%2C0%2C547.8%2C3139.181Zm-1.666%2C15.856-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(-8125.804%208362.819)%22%20fill%3D%22%23e18935%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_464%22%20data-name%3D%22%E3%83%91%E3%82%B9%20464%22%20d%3D%22M546.138%2C3155.037l-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(-8125.804%208362.819)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
      }
      &:hover {
        background-color: #E18935;
        color: #fff;
      }
    }
  }
  .blue & {
    #{$this}--lead {
      color: #3AA3AE;
    }
    #{$this}--date_label {
      background-color: #3AA3AE;
    }
    #{$this}--link {
      color: #3AA3AE;
      border: 2px solid #3AA3AE;
      &:after {
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_551%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20551%22%20transform%3D%22translate(7588%20-11502)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_463%22%20data-name%3D%22%E3%83%91%E3%82%B9%20463%22%20d%3D%22M547.8%2C3139.181a10%2C10%2C0%2C1%2C0%2C10%2C10A10%2C10%2C0%2C0%2C0%2C547.8%2C3139.181Zm-1.666%2C15.856-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(-8125.804%208362.819)%22%20fill%3D%22%233aa3ae%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_464%22%20data-name%3D%22%E3%83%91%E3%82%B9%20464%22%20d%3D%22M546.138%2C3155.037l-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(-8125.804%208362.819)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
      }
      &:hover {
        background-color: #3AA3AE;
        color: #fff;
      }
    }
  }
  @include sp {
    padding-top: 0;
    padding-bottom: 0;
    max-width: 100%;
  }
  &--lead {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.875;
    margin-bottom: 5px;
    color: #399F6E;
    @include sp {
      font-size: 18px;
    }
  }
  &--desc {
    font-size: 16px;
    line-height: 1.7;
    margin-bottom: 18px;
    @include sp {
      font-size: 14px;
    }
  }
  &--date {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 32px;
    @include sp {
      margin-top: -8px;
      margin-bottom: calc(20px - 8px);
    }
  }
  &--date_label {
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    background-color: #399F6E;
    padding: 5px 24px 6px 16px;
    margin-right: 12px;
    clip-path: polygon(0 0, 100% 0, calc(100% - 12px) 100%, 0 100%);
    @include sp {
      font-size: 14px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
  &--date_text {
    font-weight: bold;
    font-size: 20px;
    padding-top: 3px;
    @include sp {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  &--date_text_note {
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    margin-top: 8px;
    @include sp {
      font-size: 14px;
      margin-top: 4px;
    }
  }
  &--link {
    display: block;
    max-width: 275px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    padding: 20px 0;
    color: #399F6E;
    border: 2px solid #399F6E;
    position: relative;
    transition: 0.2s;
    &:after {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_551%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20551%22%20transform%3D%22translate(7588%20-11502)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_463%22%20data-name%3D%22%E3%83%91%E3%82%B9%20463%22%20d%3D%22M547.8%2C3139.181a10%2C10%2C0%2C1%2C0%2C10%2C10A10%2C10%2C0%2C0%2C0%2C547.8%2C3139.181Zm-1.666%2C15.856-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(-8125.804%208362.819)%22%20fill%3D%22%23399f6e%22%2F%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_464%22%20data-name%3D%22%E3%83%91%E3%82%B9%20464%22%20d%3D%22M546.138%2C3155.037l-.438-1.756%2C4.316-4.3-4.316-4.3.438-1.754%2C6.07%2C6.056Z%22%20transform%3D%22translate(-8125.804%208362.819)%22%20fill%3D%22%23fff%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
      top: calc(50% - 10px);
      right: 16px;
    }
    &:hover {
      background-color: #399F6E;
      color: #fff;
    }
    @include sp {
      max-width: 230px;
      margin: 0 auto;
      padding: 10px 0;
      font-size: 16px;
    }
  }
  &--online_label {
    position: absolute;
    left: 325px;
    bottom: 40px;
    @include sp {
      position: relative;
      left: auto;
      bottom: auto;
      text-align: center;
      margin-top: 16px;
    }
  }
  &--online_label_text {
    font-size: 20px;
    font-weight: $fw-bold;
    color: #fff;
    background: #02679B;
    padding: 3px 16px;
    border: 1px solid #02679B;
    border-radius: 19px;
    @include sp {
      font-size: 18px;
    }
  }
}
